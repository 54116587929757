import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
// components
import { NavSectionVertical } from '../../../../components/nav-section';
import NavItem from './NavItem';
export default function NavList({ item, activeId }) {
    const { pathname } = useLocation();
    const { path, children } = item;
    const { isExternalLink } = useActiveLink(path, activeId);
    const [open, setOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: item, open: open, onClick: () => setOpen(!open), active: pathname === path, isExternalLink: isExternalLink }), !!children && (_jsx(Collapse, { in: open, unmountOnExit: true, children: _jsx(NavSectionVertical, { data: children, sx: {
                        '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                            height: 160,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            bgcolor: 'background.neutral',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                            '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                        },
                    } }) }))] }));
}
